@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased !important; 
  text-rendering:optimizeLegibility !important;
}

body {
  background: url("/img/background.png");
  margin: 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #004dca;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

table td {
  padding: 2px 16px;
  vertical-align: top;
}

.title {
  display: none;
}

.title-image {
  margin-bottom: 16px;
}

.map {
  max-width: 640px;
  width: 100%;
  aspect-ratio: 4/3;
  margin-top: 16px;
}
